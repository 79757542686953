import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import SimpleGrids from './components/SimpleGrids'

//https://material-ui.com/system/spacing/
import { spacing } from '@material-ui/system';

//https://material-ui.com/customization/color/#color
import yellow from '@material-ui/core/colors/yellow';

const useStyles = makeStyles({
  
});
//'#ffeb3b',
// https://material-ui.com/customization/color/


export default function NoRoute() {

    const classes = useStyles();
    let history = useHistory();    

    // https://reacttraining.com/react-router/web/example/no-match
    let componentCopy = [ 
      {
        title : "404",
        sections : [
          {
            body:"That’s an error.\n\n The requested URL was not found on this server. That’s all we know.",
          },
        ],
      },
    ]

      //  hidden
      //  https://material-ui.com/customization/breakpoints
      //<Box mx="auto" marginTop="20px" maxWidth="800px">

    return (

      <div className="details-container">
         <SimpleGrids groups={componentCopy} />
      </div>
    );
  }

  
