import React, { Component, useState } from 'react';

class ContentFormatUtil {

  formatExplicitNewlines(inputText) {
    return  inputText != null ? inputText.split('\\n').map((item, i) => {
        return <span key={i}>{item}<br /></span>;
      }) : null
  }
}

export default ContentFormatUtil;
