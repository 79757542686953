import React, { Component, useState } from 'react';
import {Paper, Theme, Typography, makeStyles, createStyles, Container, Box} from '@material-ui/core';
import SimpleGrid from './SimpleGrid'
import PictureSubtitle from './PictureSubtitle'

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
  },
}),
);

function groupToElements(groupJson) {
  let section1Title = groupJson.title;
  let style = groupJson.style;

  const section1Elements = []
  groupJson.sections.map((nextPictureSubtitle, index) => {
    section1Elements.push(
      <PictureSubtitle 
        key={index}
        imageSrc={nextPictureSubtitle.imageSrc} 
        imageSubtitle={nextPictureSubtitle.imageSubtitle} 
        title={nextPictureSubtitle.title} 
        body={nextPictureSubtitle.body}
        style={nextPictureSubtitle.style}
        urlmap={nextPictureSubtitle.urlmap} 
    />);
  });

  return {section1Title, section1Elements};
}

export default function SimpleGrids(props) {

  const classes = useStyles();

  const groupElements = []
  props.groups.map((nextGroup, index) => {
    const {section1Title, section1Elements} = groupToElements(nextGroup);
    groupElements.push(
      <Box key={index} mx="auto" margin="20px">
        <SimpleGrid title={section1Title}>
          {section1Elements}
        </SimpleGrid>
      </Box>
    )
  });

// https://reactjs.org/docs/composition-vs-inheritance.html
// see containment
/*
Some components don’t know their children ahead of time. This is especially common for components like Sidebar or Dialog that represent generic “boxes”.

We recommend that such components use the special children prop to pass children elements directly into their output:
*/
    return (
      <Box>
        {groupElements}
      </Box>
    );
  }