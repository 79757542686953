import React, { Component, useState, useEffect } from 'react';

import { Card, CardMedia, CardActions, Button,CardContent, Typography } from '@material-ui/core';

import {GridList, GridListTile,GridListTileBar} from '@material-ui/core';

import { MuiThemeProvider,Theme,createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import { WithStyles, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


import { Link as RouterLink,  BrowserRouter as Router  } from 'react-router-dom';

import { useHistory } from "react-router-dom";

import Image from 'material-ui-image'

import LazyLoad from 'react-lazyload';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import './weddingphotos.css';

//https://css-tricks.com/snippets/css/a-guide-to-flexbox/
const useStyles = makeStyles(theme => ({
  root : {
    paddingTop: theme.spacing(1),
    
    /* We first create a flex layout context */
    display: 'flex',

    /* Then we define the flow direction 
     and if we allow the items to wrap 
    * Remember this is the same as:
    * flex-direction: row;
    * flex-wrap: wrap;
    */
    flexFlow: 'row wrap',
    backgroundColor: '#FFFEED',

    /* Then we define how is distributed the remaining space, so we can center */
    justifyContent: 'space-around',
  },

  gridList : {
    maxWidth: '1024px',
    margin:'auto',
  },
  gridListItem : {
    cursor: 'pointer'
  },
  gridListImg : {
    objectFit:'contain'
  },
  galleryroot : {
    backgroundColor: '#FFFEED',
  },
}));

//'#ffeb3b',
// https://material-ui.com/customization/color/


export default function WeddingPhotos(props) {
    let history = useHistory();    

    const classes = useStyles();

    const isMobileScreenSize = useMediaQuery('(max-width: 600px)'); 

    const [datasource, setDatasource] = useState({ models: [] });

    function getModelURL() {
      if(window.location.hostname == "localhost") {
        return "http://localhost:9000/model.json"
      }
      else {
        return "2020-photos-bee0e686-3211-4465-bb9c-ad504a876cae/model.json"
      }
    }

    function getImagePrefixURL() {
      // if(window.location.hostname == "localhost") {
      //   return "http://localhost:9000/"
      // }
      // else {
        return "2020-photos-bee0e686-3211-4465-bb9c-ad504a876cae/"
      //}
    }

    useEffect(() => {

      async function fetchData() {
        var fetchModelRequest = new Request(getModelURL(), {
          method: 'GET'
        });
    
        const response = await fetch(fetchModelRequest)
  
        var contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          var jsonResponse = await response.json();
          jsonResponse.models = jsonResponse.models.sort((a, b) => (a.creationDateEpoch- b.creationDateEpoch))

          setDatasource(jsonResponse);

          /*
          if(useGallery) {
            var imagePrefix = getImagePrefixURL(); 

            // react gallery https://www.npmjs.com/package/react-image-gallery
            var galleryModel = []

            for(const nextAssetModel of jsonResponse.models) {

              galleryModel.push({
                "original": imagePrefix + nextAssetModel.rendition.name,
                "thumbnail": imagePrefix + nextAssetModel.rendition.name,
              });

            }

            setGallerydatasource(galleryModel)
            console.log(galleryModel)
            
          }
          */
        }
      }
      fetchData();
    }, []); /* only fetch ONCE */

    const handleImageClick = event => {
      console.log(event.currentTarget)
      const win = window.open(event.currentTarget.dataset.original, '_blank');
      if (win != null) {
        win.focus();
      }
    };
    

    // image
    // https://www.npmjs.com/package/material-ui-image

    // lazy load
    // https://www.npmjs.com/package/react-lazyload

   
    if(datasource.models != null && datasource.models.length > 0) {

      return (
        <div>
          <Typography variant="h3" align="center">
          {datasource.models.length} Wedding Photos from July 18th 2020
          </Typography><p/>
          <div className={classes.root}>
            <GridList cellHeight={320} cols={isMobileScreenSize ? 1 : 3} className={classes.gridList}  spacing={isMobileScreenSize ? 4 : 12} >
            {datasource.models.map(nextAssetModel => (
                  
                  <GridListTile key={nextAssetModel.rendition.name} cols={1} className={classes.gridListItem} onClick={handleImageClick} 
                  data-src={getImagePrefixURL() + nextAssetModel.rendition.name}
                  data-original={getImagePrefixURL() + nextAssetModel.original.name}
                  >
                    <LazyLoad height={nextAssetModel.rendition.height}>
                      <Image src={getImagePrefixURL() + nextAssetModel.rendition.name} width='100%' height='100%' className={classes.gridListImg}
                      color={"transparent"}/>
                    </LazyLoad>
                  </GridListTile>          
            ))}
            </GridList>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={classes.root}>Loading...</div>
      );
    }
  }