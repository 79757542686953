import React, { Component, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {Paper, Container, WithStyles, makeStyles, createStyles} from '@material-ui/core';
import ContentFormatUtil from './ContentFormatUtil'

//https://www.npmjs.com/package/react-linkify
import Linkify from 'react-linkify';

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
  },
  subcontainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  pic: {
    //https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing
    boxSizing: 'border-box',
    maxWidth:'100%',
  }
}),
);

export default function PictureSubtitle(props) {
  const classes = useStyles();
  const formatUtil = new ContentFormatUtil();


  let imageSubtitle = formatUtil.formatExplicitNewlines(props.imageSubtitle);
  let title = formatUtil.formatExplicitNewlines(props.title);
  let body = formatUtil.formatExplicitNewlines(props.body);

  let bodyAlign = (props.style != null && props.style.bodyAlign != null) ? props.style.bodyAlign : "center";
  let imageHeight = (props.style != null && props.style.imageHeight != null) ? props.style.imageHeight : 'inhereted';
  let containerWidth = '640px';

  let urlToTextLookup = props.urlmap;

  const textDecorator = (text) => {
    var output = text;
    if(urlToTextLookup != null) {
      var lookup = urlToTextLookup[text];
      if(lookup != null) {
        output = lookup;
      }
    }
    return output;
  };



  //https://github.com/tasti/react-linkify/pull/51
  // private site no reffer and no access
  const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noreferrer noopener">
    {text}
  </a>
);
//'<img id="pic" src={props.imageSrc} width={imageWidth} height={imageHeight} className={classes.pic}/>'
  // newlines https://github.com/mui-org/material-ui/issues/9189
    return (
      <Container style={{maxWidth: containerWidth}}>
        <Container align="center" hidden={props.imageSrc != null ? false : true}>
          <Paper style={{backgroundColor: 'transparent'}} elevation={3} className={classes.subcontainer}>
            <img id="pic" src={props.imageSrc} style={{maxHeight: imageHeight, boxSizing: 'border-box',
    maxWidth:'100%',}}/>
              <Typography variant="subtitle2" align="center">
              {imageSubtitle}
              </Typography>
          </Paper>
        </Container>

        <Typography variant="h4" align="center" style={{whiteSpace: 'pre-line'}} className={classes.subcontainer}>
        {title}
        </Typography>
        <Typography variant="body1" align={bodyAlign} style={{whiteSpace: 'pre-line'}}>
        <Linkify textDecorator={textDecorator} componentDecorator={componentDecorator}>
        {body}
        </Linkify>
        </Typography>
      </Container>
    );

    /*
      <Container>
        <Paper style={{backgroundColor: (imageClearBackground ? 'transparent' : 'inheret') }} elevation={3}>
            <Container align="center">
              <img id="pic" src={props.imageSrc} width={imageWidth} height={imageHeight} alt="eleven madison park"/>
            </Container>
            <Typography variant="subtitle2" align="center">
            {imageSubtitle}
            </Typography>
        </Paper>

        <Typography variant="h5" align="center" style={{whiteSpace: 'pre-line'}} className={classes.root}>
        {title}
        </Typography>
        <Typography variant="body1" align={bodyAlign} style={{whiteSpace: 'pre-line'}}>
        {body}
        </Typography>
      </Container>
    */
  }