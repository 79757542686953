import React, { Component, useState } from 'react';
import {Paper, Theme, Typography, makeStyles, createStyles, Container, Box} from '@material-ui/core';

import ContentFormatUtil from './ContentFormatUtil'

// flex https://css-tricks.com/snippets/css/a-guide-to-flexbox/
const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    marginBottom: theme.spacing(10)
  },
  title : {
    marginBottom: theme.spacing(2),
    whiteSpace: 'pre-line'
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    justifyContent:'center',
    flex:'none',
    '& > *': {
      margin: theme.spacing(0),
    },
  },
}),
);

export default function SimpleGrid(props) {

const classes = useStyles();
const formatUtil = new ContentFormatUtil();

let title = formatUtil.formatExplicitNewlines(props.title);

// https://reactjs.org/docs/composition-vs-inheritance.html
// see containment
/*
Some components don’t know their children ahead of time. This is especially common for components like Sidebar or Dialog that represent generic “boxes”.

We recommend that such components use the special children prop to pass children elements directly into their output:
*/
    return (
      <Box className={classes.root}>
        <Typography variant="h3" align="center" className={classes.title}>
          {title}
        </Typography>
        <Box className={classes.flexContainer} >
          {props.children}
        </Box>
      </Box>
    );
  }