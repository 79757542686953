import React, { Component, useState } from 'react';

import './homecontent.css';
import Analytics from '../analytics.js'

import { MuiThemeProvider,Theme,createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import { WithStyles, createStyles } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

import Hidden from '@material-ui/core/Hidden';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import SimpleGrid from './components/SimpleGrid'

import { Link as RouterLink,  BrowserRouter as Router  } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { useHistory } from "react-router-dom";

//https://material-ui.com/system/spacing/
import { spacing } from '@material-ui/system';

//https://material-ui.com/customization/color/#color
import yellow from '@material-ui/core/colors/yellow';

const useStyles = makeStyles({
  news : {
    marginTop:20
  },
  newsItemContainer : {

  },
  subtitle : {
    marginLeft: 15
  }
});

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#FADE8A',
    },
  }
});

//'#ffeb3b',
// https://material-ui.com/customization/color/


export default function HomeContent() {

    const classes = useStyles();

    let history = useHistory();

    const onRSVP = event => {
      history.push("/rsvp");
    };

    const onVideoPlay = event => {
      var videoName = event.currentTarget.dataset.analyticname;
      
      if(videoName != null) {
        const analytics = new Analytics();
        analytics.recordEvent("video", "play", videoName);
      }
    }

    // spacing
    // https://material-ui.com/system/spacing/#api
    return (
      <div>  

        <Box mx="auto" marginTop="20px" maxWidth="800px" minWidth="320px">

          <div className="imageContainer">
          <img id="pic" src="/static-assets/dc2d91376a4fc72c0dfd2097e39f7eb2be62bc19b04ac206c31280a9123378ce.jpeg" width="100%" height="100%" alt="amalfi"/>

            <span className="rsvpBox">
              
              <span className="rsvpBoxText">
            <Typography variant="h2">
Nick &amp; Claire are getting married!<br />
Ceremony &amp; Reception Summer 2021<br />
New York City
</Typography>
</span>

              <MuiThemeProvider theme={customTheme}>
                <Box marginTop={1}>
                {/*
                <Button variant="contained" color="primary" onClick={onRSVP}>
                  RSVP
                </Button>
                */}
                </Box>
              </MuiThemeProvider>
            </span>
          </div>

          <Box className={classes.news}>
            <Typography variant="h2">
              Updates from Nick &amp; Claire
            </Typography>

            <p />
            <Paper elevation={3}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                  After careful consideration of local health restrictions and our guests safety we've postponed our original Summer 2020 celebration to Summer 2021.
                  We hope this delay will allow our family and friends to attend and celebrate in a safe environment!                   
                  More details to follow at which point we'll send out fresh new invitations in early 2021.
                  We're both so excited to see you all soon!
              </Typography>
            </Paper>
            <p />
            { /*
            <Paper elevation={3}>
              <video data-analyticname="welcome" width="100%" height="100%" onPlay={onVideoPlay} controls preload="none" autoPlay={false} poster="/static-assets/home_video_poster_b8acf03ffe487f33fb2a4c021ca26e38f4d0dc32ee639ea937587d4d62f03d43.jpg">
                <source type="video/webm" src="/static-assets/webm_v9_1080_adcaadd200bba950a7a631e08151539124df9f9b52daec9fdf26fb8426b9c4d1.webm" />
                <source type="video/mp4; codecs=hevc" src="/static-assets/h265_1080_afa31db60fed7ca97a981efad5b43248f9299be6d0d1e5d0a0618894eb99b3c4.4v" />
                <source type="video/mp4" src="/static-assets/h264_720p_d6257e646ca5623c21b99d07cb64daeeb2be6e2b2b45e16f0437f97a8b0fc20a.m4v" />
              </video>

              <Typography variant="subtitle1" className={classes.subtitle}>
                  Welcome!
              </Typography>
            </Paper>
            */
            }
          </Box>
        </Box>
      </div>
    );
  }