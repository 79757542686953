import ReactGA from 'react-ga';

class Analytics {

	trackingId = 'UA-159423937-1'

	constructor() {
		var isDebugMode = window.location.hostname === "localhost"
		|| window.location.hostname === "127.0.0.1"
		|| window.location.hostname === ""

		this.shouldInitGA = !isDebugMode
	}

	isEnabled() {
		return this.shouldInitGA;
	}

	recordEvent(_category, _action, _label) {
		if(this.isEnabled()) {
			ReactGA.event({
				category: _category,
				action: _action,
				label: _label
			});
		}
	}
}

export default Analytics;