import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './home';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';

import ComingSoon from './pages/comingsoon'
import HomeContent from './pages/homecontent'
import RSVP from './pages/rsvp'
import Details from './pages/details'
import Accommodations from './pages/accommodations'
import PhotoDetails from './pages/photodetails'
import Registry from './pages/registry'

import Photos from './pages/photos'
import WeddingPhotos from './pages/weddingphotos'
import NoRoute from './pages/404'

import Analytics from './analytics.js'
import GAListener from './galistener'

import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider,ThemeProvider, makeStyles,createMuiTheme } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';

//https://material-ui.com/customization/palette/
const theme = createMuiTheme({
    palette: {
      background: {
          default: '#FFFEED',
          paper: '#FADE8A'
      },
      text : {
          primary: '#000000',
      },   
    },
  });



  // https://material-ui.com/customization/typography/#typography
  theme.typography.h1 = { // Nick & Claire homepage
    fontSize: '2.1rem'
  };

  // https://material-ui.com/customization/breakpoints/
  theme.typography.h2 = { // wedding subtitle
    fontSize: '1.8rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.0rem',
    },
  };

  theme.typography.h3 = { // wedding subtitle
    fontSize: '1.8rem',
  };

  theme.typography.h4 = { // wedding subtitle
    fontSize: '1.5rem',
  };


  // background 1 lght yellow '#FADE8A'
  // background 2 dark yellow '#FFFEED'
  // purple '#4a148c'
  
  /*
  //https://material-ui.com/customization/palette/
  const theme = createMuiTheme({
    palette: {
      primary: A100,
      secondary: yellow
    },
  });
  */
  
const Main = () => (
    <main>
        <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
            <Route path='/' component={Home}/>
            <GAListener>
              <Switch>
                <Route exact path="/" component={HomeContent}/>
                {/*
                <Route exact path='/comingsoon' component={ComingSoon}/>
                <Route exact path='/rsvp' component={RSVP}/>
                <Route exact path='/details' component={Details}/>
                <Route exact path='/accommodations' component={Accommodations}/>
                <Route exact path='/photodetails' component={PhotoDetails}/>
                <Route exact path='/registry' component={Registry}/>
                */
               <Route exact path='/_/00claire' component={WeddingPhotos}/>
                }
                <Route component={NoRoute} />
              </Switch>
            </GAListener>
        </BrowserRouter>
        </MuiThemeProvider>
    </main>
    // 2020photos-bee0e686-3211-4465-bb9c-ad504a876cae
    //                 <Route exact path='/photos' component={Photos}/>
);

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();