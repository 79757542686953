import {Location, LocationListener, UnregisterCallback} from 'history'
import {useEffect} from 'react'
import ReactGA from 'react-ga'
import {useHistory} from 'react-router'
import Analytics from './analytics'

const sendPageView: LocationListener = (location: Location): void => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
    console.debug("GA|Pageview Sent: ", location.pathname)
}

interface Props {
    children: JSX.Element;
}
const GAListener = ({ children }: Props): JSX.Element => {
    const history = useHistory()
    useEffect((): UnregisterCallback | void => {
		const analytics = new Analytics();
		
		if(analytics.isEnabled()) {
            ReactGA.initialize(analytics.trackingId)
            sendPageView(history.location, 'REPLACE')
            return history.listen(sendPageView)
        }
    }, [history])

    return children
}

export default GAListener