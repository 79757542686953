import React, { Component, useState } from 'react';
import './home.css';
import { MuiThemeProvider,Theme,createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import { WithStyles, createStyles } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

import Hidden from '@material-ui/core/Hidden';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { Link as RouterLink,  BrowserRouter as Router  } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { useHistory } from "react-router-dom";

//https://material-ui.com/system/spacing/
import { spacing } from '@material-ui/system';

//https://material-ui.com/customization/color/#color
import yellow from '@material-ui/core/colors/yellow';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabindicator : {
      backgroundColor : "transparent"
  }
});

const themeStyles = makeStyles((theme) =>
createStyles({
  homeTabs : {
    variant: 'scrollable',
    scrollButton:'auto'
  }
}));
//'#ffeb3b',
// https://material-ui.com/customization/color/


export default function Home() {

    const classes = useStyles();
    const classes2 = themeStyles();
    const [tabvalue, setTabvalue] = React.useState("");
    const [mobileMenuState, setMobileMenuState] = React.useState(null);
    
    let history = useHistory();

    // tab desktop view
    const handleTabChange = (event, newValue) => {
      setTabvalue(newValue);

      history.push("/" + newValue)
    };
    //

    // mobile menu 
    const handleMobileMenuOpened = event => {
      setMobileMenuState(event.currentTarget);
    };
  
    const handleMobileMenuClose = () => {
      setMobileMenuState(null);
    };

    const handleMobileMenuTapped = event => {
      setMobileMenuState(null);

      history.push("/" + event.currentTarget.dataset.value)
    };
    

    //


      //  hidden
      //  https://material-ui.com/customization/breakpoints
      
      // variants in properties variant={{ xs: '"scrollable"', lg: 'none', xl: 'block' }} 
      // https://material-ui.com/system/display/
    return (
      <div className="home-container">  
        <Paper>
            <Typography variant="h1" gutterBottom align="center">
            Nick & Claire
            </Typography>

        {/*
          <Hidden smUp>
            <Box display="flex">
              <Box m="auto">
                <Button aria-controls="mobile-menu" aria-haspopup="true" onClick={handleMobileMenuOpened}>
                  Wedding Menu
                </Button>
              </Box>
            </Box>
            <Router>
            <Menu
              id="mobile-menu"
              anchorEl={mobileMenuState}
              keepMounted
              open={Boolean(mobileMenuState)}
              onClose={handleMobileMenuClose}
            >
              <MenuItem data-value="" onClick={handleMobileMenuTapped}>Home</MenuItem>
              <MenuItem data-value="details" onClick={handleMobileMenuTapped}>Wedding Details</MenuItem>
              <MenuItem data-value="accommodations" onClick={handleMobileMenuTapped}>Accommodations</MenuItem>
              <MenuItem data-value="registry" onClick={handleMobileMenuTapped}>Registry 🚧</MenuItem>
              <MenuItem data-value="photodetails" onClick={handleMobileMenuTapped}>Photos 🚧</MenuItem>
              <MenuItem data-value="rsvp" onClick={handleMobileMenuTapped}>RSVP</MenuItem>
            </Menu>
            </Router>
          </Hidden>

          
          <Hidden only={['xs', 'lg', 'xl']}>
            <Tabs
              value={tabvalue}
              onChange={handleTabChange}
              overflow="visible"
              variant='scrollable'
              scrollButtons='auto'
              classes={{
                indicator: classes.tabindicator,
              }}
            >
              <Tab label="Home" value=""/>
              <Tab label="Wedding Details" value="details"/>
              <Tab label="Accommodations" value="accommodations" />
              <Tab label="Registry 🚧" value="registry" />
              <Tab label="Photos 🚧" value="photodetails" />
              <Tab label="RSVP" value="rsvp" />
            </Tabs>
          </Hidden>


          <Hidden mdDown>
            <Tabs
              value={tabvalue}
              onChange={handleTabChange}
              overflow="visible"
              classes={{
                indicator: classes.tabindicator,
              }}
              centered
              variant='fullWidth'
              scrollButtons='off'
            >
              <Tab label="Home" value=""/>

              <Tab label="Wedding Details" value="details"/>
              <Tab label="Accommodations" value="accommodations" />
              <Tab label="Registry 🚧" value="registry" />
              <Tab label="Photos 🚧" value="photodetails" />
              <Tab label="RSVP" value="rsvp" />
            </Tabs>
          </Hidden>
            */}
        </Paper>
      </div>
    );
  }